<template>
  <footer>
    <div class="section" id="footer-section">
      <div class="w-100 bg-primary divider"></div>
      <div class="container">
        <div class="row justify-content-center d-flex align-middle">
          <div class="col-12 col-md-9 col-lg-7 col-xl-6 pt-2 pb-3">
            <div class="row">
              <a
                target="_blank"
                href="https://chat.banano.cc"
                class="grow-4 col-3 col-md mt-4 mb-3 mb-md-0 w-100"
              >
                <img class="w-100 icon" src="../assets/img/social/discord-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://www.reddit.com/r/banano"
                class="grow-4 col-3 col-md mt-4 mb-3 mb-md-0 w-100"
              >
                <img class="w-100 icon" src="../assets/img/social/reddit-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://twitter.com/bananocoin"
                class="grow-4 col-3 col-md mt-4 mb-3 mb-md-0 w-100"
              >
                <img class="w-100 icon" src="../assets/img/social/twitter-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://t.me/banano_official"
                class="grow-4 col-3 col-md mt-4 mb-3 mb-md-0 w-100"
              >
                <img class="w-100 icon" src="../assets/img/social/telegram-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://medium.com/banano"
                class="grow-4 col-3 col-md mt-4 w-100"
              >
                <img class="w-100 icon" src="../assets/img/social/medium-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/bananocurrency/"
                class="grow-4 col-3 col-md mt-4 w-100"
              >
                <img class="w-100 icon" src="../assets/img/social/facebook-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/bananocurrency/"
                class="grow-4 col-3 col-md mt-4 w-100"
              >
                <img class="w-100 icon" src="../assets/img/social/instagram-icon.svg">
              </a>
              <a
                target="_blank"
                href="https://www.github.com/bananocoin"
                class="grow-4 col-3 col-md mt-4 w-100"
              >
                <img class="w-100 icon" src="../assets/img/social/github-icon.svg">
              </a>
            </div>
          </div>
          <div class="col-12">
            <a target="_blank" href="https://www.banano.cc">
              <p class="text-primary text-center mb-3 mt-4 grow">
                ©2019
                <span class="font-weight-extrabold">BANANO</span>
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
   
<script>
import Vue from "vue";

export default Vue.extend({
  name: "Footer",
});
</script>