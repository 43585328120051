<template>
  <div class="row align-items-center d-flex justify-content-around my-2 my-md-3">
    <div class="col">
        <img class="w-100 grow opacity-animation" src="../assets/img/loading-monkey.svg">
    </div>
    <div
      class="col-7 col-md-8 col-lg-9 py-1 py-md-3 py-lg-4 mr-4 mr-md-5 bg-secondary grow-2 glow-purple speech-bubble-purple opacity-animation-2"
    >
      <div class="row align-items-center align-middle d-flex justify-content-between py-2">
        <h3
          class="text-left ml-4 font-weight-bold text-light bg-light rounded-100 px-3 my-2 opacity-animation"
        >hello world</h3>
        <h5
          class="text-left mx-4 font-weight-light text-light bg-light rounded-100 px-3 mx-2 opacity-animation"
        >22:15 / Thu, 16 Mar 2019</h5>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "ChatListItemDummy"
});
</script>